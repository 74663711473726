import React from 'react'
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useActiveMenu } from "react-active-menu";



import img1 from "../images/bunfestival/bunfestival-1.png";
import img2 from "../images/bunfestival/bunfestival-2.png";
import img3 from "../images/bunfestival/bunfestival-3.png";
import img5 from "../images/bunfestival/bunfestival-5.png";
import img6 from "../images/bunfestival/bunfestival-6.png";
import img8 from "../images/bunfestival/bunfestival-8.png";
import img9 from "../images/bunfestival/bunfestival-9.png";
import Map from "../images/bunfestival/bunfestival-map.png";
import Timetable from "../images/bunfestival/bunfestival-timetable.png";

import GeneralMap from '../utils/GeneralMap';

import Seo from "../utils/seo"

import "./about.scss"

export default function BunFestival() {
    const { registerSection, registerTrigger } = useActiveMenu({
        offset: 120,
        smooth: true
    });
    return (
        <Layout>
            <Container>
                <Row>
                    <Col md={2} className="p-0">
                        <div className="sticky-menu">
                            <button className="sticky-menu-text" ref={registerTrigger("section-1")} type="button">簡介</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-2")} type="button">日程表</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-3")} type="button">AR多重宇宙</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-4")} type="button">包山嘉年華</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-5")} type="button">飄色巡遊</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-6")} type="button">儀式介紹</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-7")} type="button">長洲景點</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-8")} type="button">交通資料</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-9")} type="button">出行小貼士</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-10")} type="button">聯絡</button>
                        </div>
                    </Col>
                    <Col md={10}>
                    <h6>2023年5月更新．全港最詳盡</h6>
                        <h2 className='about_slogan'>長洲太平清醮｜2023攻略<br></br>活動時間表 + AR多重宇宙 + 飄色巡遊路線 + 交通小貼士</h2>
                        <section ref={registerSection("section-1")} className='pb-3'>
                            <img src={img2} alt="長洲太平清醮 包山" className="about_M2gether_IMG  mt-3" />
                            <Row>
                                <Col xs={12} md={6}>
                                <h4 className="pb-2" >長洲太平清醮</h4>
                                <p>2023年5月23日至27日（農曆四月初五至初九）</p>
                                <p>長洲一年一度的重要傳統節慶，始於清朝晚期，已有百多年歷史。傳說當時瘟疫蔓延，島民為求消災，延請高僧喃嘸，在北帝廟前設壇拜懺，超度水陸孤魂，之後瘟疫停止。自此以後，島民每年舉辦太平清醮，酬謝北帝神恩，保境平安。長洲太平清醮已成為全島居民共同參與的重要活動，其豐富的傳統內涵，得以在居民持續多年的參與中傳承發展。2011年，長洲太平清醮被列入中國國家級非物質文化遺產代表性項目名錄。</p>
                                </Col>
                                <Col xs={12} md={6}>
                                    <GeneralMap height={250} zoom={14} lat={22.2053537} long={114.03} ></GeneralMap>
                                </Col>
                            </Row>
                            <br></br>

                            <h4 className="pb-2" ref={registerSection("section-2")}>2023太平清醮建醮日程表</h4>
                            <a href="https://s3.ap-southeast-1.amazonaws.com/assets.m2gether.com/bunfestival-timetable-enlarge.png" target='blank'><img src={Timetable} alt="長洲太平清醮建醮日程表" className="about_M2gether_IMG" /></a>
                            <hr></hr>
                        </section>

                        <section className='pb-2'>
                        <img  ref={registerSection("section-3")} src={img1} alt="2023太平清醮建醮 AR多重宇宙" className="about_M2gether_IMG" />

                            <h4 className="pb-2">从二．長洲太平清醮 AR多重宇宙</h4>
                            <p>相隔三年的長洲太平清醮將於5月23日至27日盛大舉行！除了經典的搶包山和飄色活動，今年還有一連串生動有趣的特備節目，適合一眾同樂，潮玩長洲AR多重宇宙！</p>
                            <p>从二創作團隊致力推廣本土傳統文化活動，今年於長洲太平清醮期間策動一場揉合創意科技與文化傳統（Art-Tech-Culture）的文化推廣活動，讓遊客進一步了解傳統節日一些細節及背後意義。當中最有趣的莫過於「潮玩AR」，利用AR擴增實景互動遊戲/濾鏡的形式，於不同景點（例如：北帝廟、北社街街坊會、洪聖廟、關公忠義亭等）設立互動打卡點，在遊走長洲的同時，可以透過手機召喚虛擬包山、Ｑ版北帝爺甚至架駛張保仔海盜船，務求讓市民以全新沉浸式體驗去感受長洲特色，以另類方式保育長洲文化。</p>
                            <p>在整個的太平清醮活動中，透過一系列實體活動，包括講座、工作坊、導賞團、街頭音樂會及打卡換禮物活動，以創新科技探索長洲文化的另一面。</p>
                            
                            <a href="./ar-universe" className="temple-detail-contact">點擊了解活動詳情</a>

                            <hr></hr>
                        </section>


                        <section className='pb-3'>


                            <Row>
                                <Col xs={12} md={6}>
                                <h4 className="pb-2" ref={registerSection("section-4")}>搶包山．包山嘉年華</h4>

                                <h6>活動介紹</h6>
                            <p>搶包山是香港長洲太平清醮期間的一項傳統活動，在太平清醮最後一晚的凌晨12時舉行。參加者爬上高約13米的竹棚包山，搶奪掛滿的平安包，搶到的平安包會分派給其他居民。搶得越高的包，就有更好的運氣。然而，在1978年的搶包山活動中發生了包山坍塌的意外，導致這項傳統活動被中止了多年。直到2005年，搶包山活動才被重新啟動，並改為比賽形式，稱為「包山嘉年華」。參加者可以攀爬高達13米的包山，並在一定時間內搶奪掛滿的平安包。比賽的勝出者是在3分鐘內摘取平安包的總分數最高者。在比賽中，平安包根據高、中、低三個區域劃分為9分、3分和1分。</p>
                            <p>5月26日晚上10時開始派發免費入場券，市民可到長洲消防局側的北社一里沿冰廠路排隊輪候，每名市民可獲派入場券一張，先到先得，額滿即止。</p>

                                    <h6>包山嘉年華2023活動安排</h6>
                                    <p>地點：長洲北帝廟遊樂場足球場</p>
                                    <ul>
                                        <li>搶包山比賽訓練：2023年4月16日（星期日）</li>
                                        <li>搶包山搶包山選拔賽：2023年4月30日（星期日）</li>
                                        <li>攀爬嘉年華：2023年5月14日（星期日）</li>
                                        <li>搶包山決賽暨頒獎典禮：2023年5月26日至27日（星期五、六）零晨12:00</li>
                                    </ul>
                                    資料來源：<a href="https://www.lcsd.gov.hk/tc/bun/index.html" className="temple-detail-contact" target='blank'>康樂及文化事務署</a>

                                </Col>
                                <Col xs={12} md={6}>
                                <img src={img9} alt="包山嘉年華2023" className="about_M2gether_IMG" />
                                <img src={img6} alt="搶包山" className="about_M2gether_IMG" />

                                    <GeneralMap height={300} zoom={18} lat={22.2120838} long={114.027133}></GeneralMap>
                                </Col>
                            </Row>
                            <hr></hr>
                        </section>

                        <section className='pb-3'>
                        <img ref={registerSection("section-5")} src={img5} alt="飄色巡遊2023" className="about_M2gether_IMG" />


                            <Row>
                                <Col xs={12} md={6}>
                                <h4 className="pb-2">飄色巡遊</h4>
                        <p>2023年5月26日（星期五）下午2:00至4:00</p>
                            <p>起源於居民扮成神明出巡、驅除瘟神的習俗，現在已演變成小孩裝扮成神明或古今人物，而近年主辦單位亦以香港當年的大事新聞作飄色主題。在飄色巡遊中，小孩化妝後做靜態的人物造型或簡單的動作表演，站在支架上遊行，給人一種「凌空飄然」的視覺效果。</p>
                            <p>活動路線：由北社街出發，沿新興街、大興堤路(部份)、中學路、中興街、大菜園路、大新後街、興隆正街、新興後街轉入新興街，最後返回北社街為終點。</p>
                                </Col>
                                <Col xs={12} md={6}>
                                <iframe title="飄色巡遊路線" src="https://www.google.com/maps/d/embed?mid=1gneqMsZwaBBljpJaVgFTbtMIXL8VcTs&ehbc=2E312F" width="100%" height="350"></iframe>
                                </Col>
                            </Row>

                            <hr></hr>
                        </section>

                        <section ref={registerSection("section-6")} className='pb-3'>
                        <h4 className="pb-2" >海陸豐人特有打醮風俗</h4>
                        <p>在打醮儀式中，會使用喃嘸先生，並且在場中擺放大士王、土地、山神等神明像，以及包山和飄色等道具。這些都是海陸豐人獨特的元素，與其他地方的打醮有所不同。在長洲太平清醮中，打醮儀式包括迎神儀式、走午朝、水祭、走船、會景巡遊、祭幽、謝天地、搶包山、分發平安包和送神。此外，打醮期間還會上演神功戲等娛樂節目，以吸引更多人參與。</p>
                            <img src={img3} alt="打醮風俗" className="about_M2gether_IMG" />
                            <Row>
                                <Col xs={3} md={2}><h6>迎神</h6></Col>
                                <Col><p>建醮的前一天舉行迎神儀式，迎神隊伍會從島上各廟宇迎請各大神祇，包括各天后廟、太平山北帝和觀音廟等。各迎神隊伍回到北帝廣場參與醮會，安奉眾神並在神棚供奉。被邀請到神棚內供奉的神靈代表其所在的團體參與太平清醮。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>開光</h6></Col>
                                <Col><p>喃嘸師傅在初五日先為各神祇開光，再到大型神棚為『大士王』、『山神』及『土地公』三尊神像開光。各善信開始到神棚祭祀，酬謝神恩。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>起醮</h6></Col>
                                <Col><p>本年起醮時辰是初六日丑時(5月24日零晨1:00)，由法師進行，期間派遣兩隻紙馬，飛到天上通知天地神明，太平清醮正式開始。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>演戲助慶</h6></Col>
                                <Col><p>神功戲是人﹑神共享的戲劇表演。神功是指為神做功德。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>齋戒</h6></Col>
                                <Col><p>在傳統上，長洲居民會在太平清醮期間齋戒三天，以表達對神明的敬意。為了推廣這個傳統，近年長洲太平清醮值理會還推出了「齊齊齋」活動，鼓勵大眾實行齋戒，部分商戶承諾遵從習俗，在打醮期間只售賣素食或暫停營業，其中亦有餐廳為長洲太平清醮而推出的「節日限定」齋戒菜式。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>走午朝</h6></Col>
                                <Col><p>儀式在初七日午時 (5月25日上午11:00)，於北帝廟前戲棚舉行。場地佈置以五行(金木水火土)佈局，設置五張方桌，向五方神明供奉衣食，祈請東、南、西、北、中斗，旨在驅魔，祈求消災解難，保佑合境平安。各桌有插滿香燭的香爐和祭品，儀式開始後道士們手持令旗法器，舞動令旗圍繞各桌奔跑，動作節奏越來越快，鑼鼓聲助威一陣比一陣響，直到完成五桌工序後，道士們仿若神明上身，令旗翻滾，彩袍繽紛。儀式完結後，民眾搶收供桌上的供品回家拜祭，以求護蔭。而黃綠色的符紙，則會在打醮完後向善信派發。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>水祭</h6></Col>
                                <Col><p>祭祀海上的無主孤魂，使水上的幽靈野鬼得以安息。船上載著喃嘸師傅及工作人員，圍繞避風塘，沿途誦經，焚燒冥鏹，超渡亡魂。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>道教祈福儀式</h6></Col>
                                <Col><p>恭迎聖駕祈福法會，儀式在初七日晚上舉行，祈請恭迎天地神明，保佑洲民一年四季風調雨順、國泰民安。法師會在桌上鋪上黑布，並用白米，鋪出『恭迎聖駕』四個大字，並由仙鶴與魂馬帶表上天庭。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>走船</h6></Col>
                                <Col><p>由法師派遣『法船』將一切污穢及瘟疫遣送出境，有送走瘟神之意，離開長洲，並由法船前往各地海域接引孤魂赴蒙山法式食大會(祭幽)。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>山祭超幽</h6></Col>
                                <Col><p>山祭超幽，通常在醮期的第三天晚上舉行。這個儀式的目的是超渡水陸孤魂，以保合境平安。在祭祀中，不僅要請兄弟先人食飯、領衣紙，還要請一批無主孤魂來共享祭品。在醮場附近設36幽席，席上擺滿了各種齋菜供品，法師誦經超度無主孤魂野鬼。祭幽時遊魂眾多，為避免騷亂，會抬出大士王來控制場面。儀式結束後，大士王會被火化，被認為帶來不幸或疾病的幽魂便會離開長洲。三天的醮會結束，正式結束齋期。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>平安包</h6></Col>
                                <Col><p>在早期，包山上的平安包並沒有印上特定的名字，最早期三座大包山的包印有各式字款，分別是「福」、「安」和「潮州」，後來統一改印「平安」。當年的「搶包」已經演變為有秩序的「派包」儀式，在太平清醮活動的最後一日，值理會工作人員會取下三座包山上的「平安包」派發予公眾，寓意「保平安」。當地鄕民相信，凡吃過包山上的包，那年定得神靈的庇佑及賜福。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>送神</h6></Col>
                                <Col><p>將請來醮棚的各神明送歸原來的地方，然後將紙神位化去，而打醮亦隨之結束。</p></Col>
                            </Row>

                            <hr></hr>
                        </section>



                        <section ref={registerSection("section-7")} className='pb-3'>
                        <h4 className="pb-2">長洲景點</h4>
                        <p>長洲是香港著名的度假勝地，曾是一個漁村，現在仍保留著濃厚的漁村風情。這裡有豐富的特色美食、自然景觀和打卡熱點，非常適合探索。長洲可以分為4個區域，中部是主要遊玩區域，有港口、沙灘和村落；北面可以登山眺望港島，有大貴灣、東灣和北角咀；西面有張保仔洞和五行石；東面則以海景為主，有小長城等景點。你可以步行觀光，也可以租單車暢遊長洲，感受這個美麗的地方帶來的獨特魅力。</p>

                            <img src={Map} alt="長洲地圖" className="about_M2gether_IMG" />
                            <Row>
                                <Col xs={3} md={2}><h6>北眺亭</h6></Col>
                                <Col><p>北眺亭位於長洲最高點。登上亭內可欣賞到東灣、觀音灣沙灘、南丫島等美景，亦是觀賞日出日落的好地方。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>北帝廟</h6></Col>
                                <Col><p>長洲北帝廟建於清朝乾隆四十八年，已有二百多年歷史，是全香港最具規模的北帝廟。當地漁民因疫症前往惠陽縣玄武山北帝廟迎請北帝神像，並建廟奉祀北帝，以求神明庇蔭，使洲民平安。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>洪聖廟</h6></Col>
                                <Col><p>長洲洪聖廟或長洲洪聖古廟位於香港長洲中興街，座東南向西北，面向石鼓洲，主要供奉主神洪聖大王，配祀觀音及華陀、華光先聖、先鋒貴人、龍母娘娘、太歲和消災壇諸神。。1987年被評為二級歷史建築，於2009年12月18日再獲確定二級歷史建築評級。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>北社天后廟</h6></Col>
                                <Col><p>長洲北社天后古廟建於清乾隆年間，已有超過250年歷史。廟宇為兩進三間式建築，供奉天后娘娘及其他神像。廟內有多份碑記，展現歷次修建及維修。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>關公忠義亭</h6></Col>
                                <Col><p>長洲小長城是一條沿著海岸線的海濱長廊，整條路線都鋪上整齊的石頭，加上在兩旁的花崗岩欄杆，因貌似萬里長城，被稱為「港版小長城」。可360度飽覽海岸景致。沿途設有各種形態獨特的大型奇石，合稱「十六肖石頭」，包括海狗石、玉璽石、叮噹石等等，有觀景台及指示牌介紹石頭的位置、名稱或形態，十分值得一遊。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>小長城</h6></Col>
                                <Col><p>長洲小長城是一條沿著海岸線的海濱長廊，整條路線都鋪上整齊的石頭，加上在兩旁的花崗岩欄杆，因貌似萬里長城，被稱為「港版小長城」。可360度飽覽海岸景致。沿途設有各種形態獨特的大型奇石，合稱「十六肖石頭」，包括海狗石、玉璽石、叮噹石等等，有觀景台及指示牌介紹石頭的位置、名稱或形態，十分值得一遊。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>張保仔洞</h6></Col>
                                <Col><p>據說這個山洞是著名海盜張保仔曾經收藏寶物的地方，現已無任何寶物痕跡。洞口狹小只能容納一人，洞內黑暗無光，需下鐵梯小心前行，並攜帶電筒。</p></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>五行石</h6></Col>
                                <Col><p>在張保仔洞附近，沿著小路前行，會看到靠海邊處有五塊巨石，又稱為「五行石」。巨石坐落在山崖的邊緣，看似搖搖欲墜，別具特色！</p></Col>
                            </Row>
                            <hr></hr>
                        </section>

                        <section ref={registerSection("section-8")}className='pb-3'>
                            
                        <h4 className="pb-2">交通資料</h4>
                            <p>長洲居民約2萬人，預料太平清醮約有6萬人次參與！由於預計活動當日的乘客量會較多，來往中環和長洲的乘客或需輪候較長時間登船，市民應預早計劃行程及留意最新交通消息，並遵照警方及渡輪公司職員的指示排隊登船。</p>

                            <img src={img8} alt="新渡輪 長洲中環時間表" className="about_M2gether_IMG" />
                            <Row>
                                <Col xs={3} md={2}><h6>新渡輪</h6></Col>
                                <Col><ul>
                                    <li>從港鐵香港站前往中環5號碼頭，搭乘前往長洲的渡輪，船程介乎35至60分鐘。</li>
                                    <li>預料當日中環往長洲人流高峰為上午10時至下午1時。</li>
                                    <li>屆時新渡輪將調動所有可使用的後備船隻以增加航班數目疏導人流，高峰班次達一小時接近6班。</li>
                                    <li>可考慮於人流高峰期前，即約上午8時至10時前期間乘船前往長洲。</li>
                                    <li>5月27日凌晨1日15分增設由長洲開往中環的普通渡輪加班服務，方便市民觀賞完「搶包山」比賽後離開。</li>
                                </ul></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>巴士</h6></Col>
                                <Col><ul>
                                    <li>巴士公司亦會於5月27日約凌晨1時10分至約凌晨2時30分，加開特別巴士路線104R，由中環五號碼頭前往旺角，並於中環五號碼頭外加設一臨時巴士站，方便市民。</li>
                                    <li>由2023年5月27日首班車至約凌晨2時50分，由港澳碼頭開出的通宵巴士路線，包括城巴第N8X號(往小西灣)及第N90號(往海怡半島)、隧巴第N18號 (往廣源)、第N619號(往順利) 、第N373號 (往粉嶺)及第N368號 (往元朗西)，線將於中環5號碼頭外加設一臨時巴士站。 </li>
                                    <li>2023年5月27日的其他巴士路線改道，N930號(荃灣愉景新城) N952號(屯門置樂花園)、N962號(屯門龍門居) 巴士路線將於下述時間由干諾道中西行，改經民光街、民耀街、民祥街、民祥街地下通道及干諾道中，然後恢復行走原來路線。 </li>
                                </ul></Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={2}><h6>暫停使用的士站</h6></Col>
                                <Col><ul>
                                    <li>位於中環5號及6號碼頭外的市區的士站及過海的士站，將會於2023年5月26日凌晨4時至晚上8時59分暫停使用。</li>
                                </ul></Col>
                            </Row>
                            資料來源：<a href="https://www.td.gov.hk/filemanager/tc/content_13/20230526_BunFestival%202023_TDN_chi.pdf" className="temple-detail-contact" target='blank'>運輸署</a>
                            <hr></hr>
                        </section>

                        <section ref={registerSection("section-9")} className='pb-3'>
                        <h4 className="pb-2">出行小貼士</h4>
                            <ul>
                                <li>出發前注意天氣，準備雨具/防曬用品及扇子</li>
                                <li>留意船期和回程時間(詳情可瀏覽<a className="temple-detail-contact" href="https://www.sunferry.com.hk/" target='blank'>新渡輪</a>或<a className="temple-detail-contact" href="https://www.kmb.hk/" target='blank'>九巴</a>或<a className="temple-detail-contact" href="https://www.bravobus.com.hk/" target='blank'>新巴</a>網頁</li>
                                <li>回程乘船時可能需要花1小時或以上排隊</li>
                                <li>參加飄色巡遊時提前30分鐘找好註腳位置</li>
                                <li>搶包山建議提前2小時到派票地點排隊，如拿不到門票可以早點到比賽場地外圍的公園擺位。</li>
                            </ul>
                            <hr></hr>
                        </section>

                        <section ref={registerSection("section-10")} className='pb-3'>
                            <p>以上項目資料僅供參考，如發現資料有誤，歡迎<a className="temple-detail-contact" href="mailto:support@m2gether.com">電郵</a>指正以便我們了解及跟進。</p>
                        </section>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}


export const Head = () => (
    <Seo
        title="（5月更新．全港最詳盡）長洲太平清醮2023 活動時間表 + 飄色巡遊路線 + 交通小貼士"
        description="包山2023/長洲｜說到香港的傳統盛事，不得不提的就是長洲太平清醮了！自2005年復辦搶包山比賽，每逢太平清醮長洲都會出現大量人流，不少遊客也會專程前往感受熱鬧"
        siteUrl="http://m2gether.com/bunfestival"
    />
)




